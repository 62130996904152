import { request as _request, } from 'graphql-request';
function delayWithError(ms) {
    return new Promise((resolve) => setTimeout(() => resolve(undefined), ms));
}
async function requestWithTimeout(options, timeout) {
    const res = await Promise.race([_request(options), delayWithError(timeout)]);
    if (typeof res === 'undefined') {
        throw new Error('Request timed out');
    }
    return res;
}
export async function request(params, options = {}) {
    let remainingRetries = options.retries ?? 1;
    let errorMessage = '';
    while (remainingRetries > 0) {
        try {
            if (options.timeout) {
                return await requestWithTimeout(params, options.timeout);
            }
            else {
                return await _request(params);
            }
        }
        catch (err) {
            remainingRetries--;
            errorMessage = err.message;
        }
    }
    throw new Error(`Retries exceeded. Last error: ${errorMessage}`);
}

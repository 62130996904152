export function transformBucketsV3ToStd(buckets) {
    return buckets.map(transformBucketV3ToStd);
}
export function transformBucketV3ToStd(bucket) {
    return {
        id: bucket.id,
        date: Number(bucket.date),
        liquidityUSD: Number(bucket.liquidityUSD),
        volumeUSD: Number(bucket.volumeUSD),
        feesUSD: Number(bucket.feesUSD),
        txCount: Number(bucket.txCount),
    };
}

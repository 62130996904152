import { SUSHISWAP_V3_SUBGRAPH_URL } from 'sushi/config/subgraph';
import { requestPaged } from '../../../lib/request-paged';
import { graphql } from '../graphql';
export const SushiV3DayDatasQuery = graphql(`
  query DayDatas($first: Int = 1000, $skip: Int = 0, $block: Block_height, $orderBy: UniswapDayData_orderBy, $orderDirection: OrderDirection, $where: UniswapDayData_filter) {
    uniswapDayDatas(first: $first, skip: $skip, block: $block, orderBy: $orderBy, orderDirection: $orderDirection, where: $where) {
      id
      date
      volumeUSD
      volumeUSDUntracked
      tvlUSD
      feesUSD
      txCount
    }
  }
`);
export async function getSushiV3DayDatas({ chainId, ...variables }, options) {
    const url = `https://${SUSHISWAP_V3_SUBGRAPH_URL[chainId]}`;
    const result = await requestPaged({
        chainId,
        url,
        query: SushiV3DayDatasQuery,
        variables,
        options,
    });
    return result.uniswapDayDatas.map((dayData) => ({
        id: dayData.id,
        date: dayData.date,
        volumeUSD: Number(dayData.volumeUSD),
        volumeUSDUntracked: Number(dayData.volumeUSDUntracked),
        liquidityUSD: Number(dayData.tvlUSD),
        feesUSD: Number(dayData.feesUSD),
        txCount: Number(dayData.txCount),
    }));
}

import { SUSHISWAP_V3_SUBGRAPH_URL } from 'sushi/config/subgraph';
import { FetchError } from '../../../lib/fetch-error';
import { request } from '../../../lib/request';
import { PoolFieldsFragment } from '../../../subgraphs/sushi-v3/fragments/pool-fields';
import { transformBucketsV3ToStd } from '../../../subgraphs/sushi-v3/transforms/bucket-v3-to-std';
import { transformPoolV3ToBase } from '../../../subgraphs/sushi-v3/transforms/pool-v3-to-base';
import { graphql } from '../graphql';
export const SushiV3PoolBucketsQuery = graphql(`
  query Pool($id: ID!, $block: Block_height, $hourDataFirst: Int = 168, $dayDataFirst: Int = 1000) {
    pool(id: $id, block: $block) {
      ...PoolFields

      poolHourData(first: $hourDataFirst, orderBy: periodStartUnix, orderDirection: desc) {
        id
        date: periodStartUnix
        liquidityUSD: tvlUSD
        volumeUSD
        feesUSD
        txCount
      }

      poolDayData(first: $dayDataFirst, orderBy: date, orderDirection: desc) {
        id
        date
        liquidityUSD: tvlUSD
        volumeUSD
        feesUSD
        txCount
      }
    }
  }
`, [PoolFieldsFragment]);
export async function getSushiV3PoolBuckets({ chainId, ...variables }, options) {
    const url = `https://${SUSHISWAP_V3_SUBGRAPH_URL[chainId]}`;
    const result = await request({ url, document: SushiV3PoolBucketsQuery, variables }, options);
    if (result.pool) {
        return {
            ...transformPoolV3ToBase(result.pool, chainId),
            poolHourData: transformBucketsV3ToStd(result.pool.poolHourData),
            poolDayData: transformBucketsV3ToStd(result.pool.poolDayData),
        };
    }
    throw new FetchError(chainId, `Failed to fetch pool ${chainId}:${variables.id}`);
}

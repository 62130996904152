import { SUSHISWAP_V3_SUBGRAPH_URL } from 'sushi/config/subgraph';
import { FetchError } from '../../../lib/fetch-error';
import { addChainId } from '../../../lib/modifiers/add-chain-id';
import { requestPaged } from '../../../lib/request-paged';
import { graphql } from '../graphql';
export const SushiV3MintsQuery = graphql(`
  query Mints($first: Int = 1000, $skip: Int = 0, $block: Block_height, $orderBy: Mint_orderBy, $orderDirection: OrderDirection, $where: Mint_filter) {
    mints(first: $first, skip: $skip, block: $block, orderBy: $orderBy, orderDirection: $orderDirection, where: $where) {
      id
      owner
      sender
      origin
      amount
      amount0
      amount1
      amountUSD
      logIndex
      transaction {
        id
        timestamp
        blockNumber
      }
    }
  }
`);
export async function getSushiV3Mints({ chainId, ...variables }, options) {
    const url = `https://${SUSHISWAP_V3_SUBGRAPH_URL[chainId]}`;
    const result = await requestPaged({
        chainId,
        url,
        query: SushiV3MintsQuery,
        variables,
        options,
    });
    if (result) {
        return result.mints.map((mint) => addChainId(chainId, mint));
    }
    throw new FetchError(chainId, 'Failed to fetch mints');
}

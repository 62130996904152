import { ChainId } from "sushi/chain";
import {
  EXTRACTOR_SUPPORTED_CHAIN_IDS,
  SushiSwapV2ChainIds,
  SushiSwapV3ChainIds,
  TridentChainIds,
} from "sushi/config";
import { Currency } from "sushi/currency";

export const CHAIN_ID = Number(process.env.NEXT_PUBLIC_CHAIN_ID) as 84532

export const SWAP_API_SUPPORTED_CHAIN_IDS = EXTRACTOR_SUPPORTED_CHAIN_IDS;

export type SwapApiEnabledChainId =
  (typeof SWAP_API_SUPPORTED_CHAIN_IDS)[number];
export const isSwapApiEnabledChainId = (
  chainId: number
): chainId is SwapApiEnabledChainId =>
  SWAP_API_SUPPORTED_CHAIN_IDS.includes(chainId as SwapApiEnabledChainId);

export const DISABLED_CHAIN_IDS = [] as const;

const SUSHI_CHAIN_IDS = Array.from(
  new Set([...TridentChainIds, ...SushiSwapV2ChainIds, ...SushiSwapV3ChainIds])
);

export const SWAP_ONLY_CHAIN_IDS = [CHAIN_ID] as const;

export const CHAIN_IDS = [...SUSHI_CHAIN_IDS] as const;

export const SUPPORTED_CHAIN_IDS = [CHAIN_ID];

export const DISABLED_ANALYTICS_CHAIN_IDS = [];

export const ANALYTICS_CHAIN_IDS = [
  ...SUPPORTED_CHAIN_IDS.filter(
    (el) =>
      !DISABLED_ANALYTICS_CHAIN_IDS.includes(
        el as (typeof DISABLED_ANALYTICS_CHAIN_IDS)[number]
      )
  ),
];

export type SupportedChainId = (typeof SUPPORTED_CHAIN_IDS)[number];
export const isSupportedChainId = (
  chainId: number
): chainId is SupportedChainId =>
  SUPPORTED_CHAIN_IDS.includes(chainId as SupportedChainId);

export type Config = {
  [_chainId in SupportedChainId]: {
    stables: Currency[];
    lsds: Currency[];
  };
};

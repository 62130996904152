import { SUSHISWAP_V3_SUBGRAPH_URL } from 'sushi/config/subgraph';
import { FetchError } from '../../../lib/fetch-error';
import { requestPaged } from '../../../lib/request-paged';
import { transformPoolV3ToBase } from '../../../subgraphs/sushi-v3/transforms/pool-v3-to-base';
import { PoolFieldsFragment } from '../fragments/pool-fields';
import { graphql } from '../graphql';
export const SushiV3PoolsQuery = graphql(`
  query Pools($first: Int = 1000, $skip: Int = 0, $block: Block_height, $orderBy: Pool_orderBy, $orderDirection: OrderDirection, $where: Pool_filter) {
    pools(first: $first, skip: $skip, block: $block, orderBy: $orderBy, orderDirection: $orderDirection, where: $where) {
      ...PoolFields
    }
  }
`, [PoolFieldsFragment]);
export async function getSushiV3Pools({ chainId, ...variables }, options) {
    const url = `https://${SUSHISWAP_V3_SUBGRAPH_URL[chainId]}`;
    const result = await requestPaged({
        chainId,
        url,
        query: SushiV3PoolsQuery,
        variables,
        options,
    });
    if (result) {
        return result.pools.map((pool) => transformPoolV3ToBase(pool, chainId));
    }
    throw new FetchError(chainId, 'Failed to fetch pools');
}

import { SUSHISWAP_V3_SUBGRAPH_URL } from 'sushi/config/subgraph';
import { requestPaged } from '../../../lib/request-paged';
import { graphql } from '../graphql';
export const SushiV3SwapsQuery = graphql(`
  query Swaps($first: Int = 1000, $skip: Int = 0, $block: Block_height, $orderBy: Swap_orderBy, $orderDirection: OrderDirection, $where: Swap_filter) {
    swaps(first: $first, skip: $skip, block: $block, orderBy: $orderBy, orderDirection: $orderDirection, where: $where) {
      id
      sender
      recipient
      origin
      amount0
      amount1
      amountUSD
      logIndex
      transaction {
        id
        timestamp
        blockNumber
      }
    }
  }
`);
export async function getSushiV3Swaps({ chainId, ...variables }, options) {
    const url = `https://${SUSHISWAP_V3_SUBGRAPH_URL[chainId]}`;
    const result = await requestPaged({
        chainId,
        url,
        query: SushiV3SwapsQuery,
        variables,
        options,
    });
    return result.swaps;
}

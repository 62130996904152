import { SUSHISWAP_V3_SUBGRAPH_URL } from 'sushi/config/subgraph';
import { FetchError } from '../../../lib/fetch-error';
import { addChainId } from '../../../lib/modifiers/add-chain-id';
import { convertIdToMultichainId } from '../../../lib/modifiers/convert-id-to-multichain-id';
import { copyIdToAddress } from '../../../lib/modifiers/copy-id-to-address';
import { request } from '../../../lib/request';
import { graphql } from '../graphql';
export const SushiV3FactoriesQuery = graphql(`
  query Factories {
    factories(first: 1) {
      id
      totalValueLockedUSD
      totalVolumeUSD
      poolCount
    }
  }
`);
export async function getSushiV3Factory({ chainId, ...variables }, options) {
    const url = `https://${SUSHISWAP_V3_SUBGRAPH_URL[chainId]}`;
    const result = await request({
        url,
        document: SushiV3FactoriesQuery,
        variables,
    }, options);
    if (result.factories[0]) {
        const factory = result.factories[0];
        return convertIdToMultichainId(copyIdToAddress(addChainId(chainId, factory)));
    }
    throw new FetchError(chainId, 'Failed to fetch factory');
}

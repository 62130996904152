import { request as _request } from '../lib/request';
import { MAX_FIRST } from 'sushi/config/subgraph';
import { FetchError } from './fetch-error';
function request(url, query, variables, options) {
    return _request({ url, document: query, variables: variables }, options);
}
/**
 *
 * @brief A wrapper for graphql-request's request function that handles pagination
 * @warning This function only works with queries that have a single key in the result, the rest will be ignored
 *
 * @param chainId Required to retrieve the maxFirst value
 * @param url The URL of the subgraph
 * @param query The query to be executed
 * @param variables The variables to be passed to the query
 */
export async function requestPaged({ chainId, url, query, variables, options, }) {
    const maxFirst = MAX_FIRST[chainId];
    // No need to page
    if ('first' in variables &&
        typeof variables['first'] === 'number' &&
        variables['first'] <= maxFirst) {
        return request(url, query, variables);
    }
    if ('orderBy' in variables) {
        throw new FetchError(chainId, 'orderBy is not allowed in requestPaged');
    }
    if ('orderDirection' in variables) {
        throw new FetchError(chainId, 'orderDirection is not allowed in requestPaged');
    }
    const querySelection = query?.definitions?.[0]?.selectionSet
        ?.selections?.[0];
    if (!querySelection) {
        throw new FetchError(chainId, 'Query selection not found');
    }
    // We need the id value to paginate
    // TODO: this is wrong, this is always throwing with first: Infinity variable.
    // if (
    //   !querySelection.selectionSet.selections.find((s: any) => {
    //     return s.name.value === 'id'
    //   })
    // ) {
    //   throw new FetchError(chainId, 'Id not found in query selection')
    // }
    const key = querySelection?.name?.value;
    if (!key) {
        throw new FetchError(chainId, 'Key not found');
    }
    let lastSize = maxFirst;
    let results = [];
    let lastId = null;
    while (lastSize === maxFirst) {
        const lastIdWhere = typeof lastId === 'string' ? { id_gt: lastId } : {};
        const result = await request(url, query, {
            ...variables,
            first: maxFirst,
            where: {
                ...(variables?.['where'] ?? {}),
                ...lastIdWhere,
            },
            orderBy: 'id',
            orderDirection: 'asc',
        }, options).then((r) => {
            if (!r)
                return null;
            return Object.values(r)[0];
        });
        if (!result) {
            break;
        }
        if (result.length === 0) {
            break;
        }
        results = results.concat(result);
        lastSize = result.length;
        lastId = result[result.length - 1].id;
        if ('first' in variables &&
            typeof variables['first'] === 'number' &&
            results.length >= variables['first']) {
            results = results.slice(0, variables['first']);
            break;
        }
    }
    return { [key]: results };
}

import { SUSHISWAP_V3_SUBGRAPH_URL } from 'sushi/config/subgraph';
import { FetchError } from '../../../lib/fetch-error';
import { request } from '../../../lib/request';
import { PoolFieldsFragment } from '../../../subgraphs/sushi-v3/fragments/pool-fields';
import { transformPoolV3ToBase } from '../../../subgraphs/sushi-v3/transforms/pool-v3-to-base';
import { graphql } from '../graphql';
export const SushiV3PoolQuery = graphql(`
  query Pool($id: ID!, $block: Block_height) {
    pool(id: $id, block: $block) {
      ...PoolFields
    }
  }
`, [PoolFieldsFragment]);
export async function getSushiV3Pool({ chainId, ...variables }, options) {
    const url = `https://${SUSHISWAP_V3_SUBGRAPH_URL[chainId]}`;
    const result = await request({ url, document: SushiV3PoolQuery, variables }, options);
    if (result.pool) {
        return transformPoolV3ToBase(result.pool, chainId);
    }
    throw new FetchError(chainId, `Failed to fetch pool ${chainId}:${variables.id}`);
}

import { SUSHISWAP_V3_SUBGRAPH_URL } from 'sushi/config/subgraph';
import { request } from '../../../lib/request';
import { PoolFieldsFragment } from '../../../subgraphs/sushi-v3/fragments/pool-fields';
import { transformPoolV3ToBase } from '../../../subgraphs/sushi-v3/transforms/pool-v3-to-base';
import { graphql } from '../graphql';
export const SushiV3PoolsByTokenPairQuery = graphql(`
  query PoolsByTokenPair($where: Pool_filter!) {
    pools(first: 1000, where: $where) {
      ...PoolFields
    }
  }
`, [PoolFieldsFragment]);
export async function getSushiV3PoolsByTokenPair({ chainId, ..._variables }, options) {
    const url = `https://${SUSHISWAP_V3_SUBGRAPH_URL[chainId]}`;
    const tokens = [_variables.token0, _variables.token1].sort();
    const variables = {
        where: {
            token0: tokens[0].toLowerCase(),
            token1: tokens[1].toLowerCase(),
        },
    };
    const result = await request({
        url,
        document: SushiV3PoolsByTokenPairQuery,
        variables,
    }, options);
    return result.pools.map((pool) => transformPoolV3ToBase(pool, chainId));
}

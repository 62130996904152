import { SUSHISWAP_V3_SUBGRAPH_URL } from 'sushi/config/subgraph';
import { requestPaged } from '../../../lib/request-paged';
import { graphql } from '../graphql';
export const SushiV3BurnsQuery = graphql(`
  query Burns($first: Int = 1000, $skip: Int = 0, $block: Block_height, $orderBy: Burn_orderBy, $orderDirection: OrderDirection, $where: Burn_filter) {
    burns(first: $first, skip: $skip, block: $block, orderBy: $orderBy, orderDirection: $orderDirection, where: $where) {
      id
      owner
      origin
      amount
      amount0
      amount1
      amountUSD
      logIndex
      transaction {
        id
        timestamp
        blockNumber
      }
    }
  }
`);
export async function getSushiV3Burns({ chainId, ...variables }, options) {
    const url = `https://${SUSHISWAP_V3_SUBGRAPH_URL[chainId]}`;
    const result = await requestPaged({
        chainId,
        url,
        query: SushiV3BurnsQuery,
        variables,
        options,
    });
    return result.burns;
}
